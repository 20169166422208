import React from "react"
import PropTypes from "prop-types"

const Banner = ({ children, image }) => {
  if (!image) {
    return children
  }

  const background = {
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.75)), url(${image.asset.url})`,
  }

  return (
    <section
      className="banner d-flex flex-column justify-content-end"
      style={background}
    >
      {children}
    </section>
  )
}

Banner.propTypes = {
  children: PropTypes.node,
  image: PropTypes.shape({
    asset: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }),
}

export default Banner
