import React from "react"
import Layout from "../components/layout"
import Container from "react-bootstrap/Container"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Banner from "../components/banner"
import PageContent from "../components/page-content"

const DefaultTemplate = ({ data }) => {
  const page = data.page
  const { pageContent, title, image } = page
  return (
    <Layout>
      <Seo title={title} />
      <Banner image={image}>
        <Container style={{ maxWidth: "720px" }}>
          <h1>{title}</h1>
        </Container>
      </Banner>
      <Container style={{ maxWidth: "720px" }}>
        <PageContent content={pageContent} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      title
      image {
        alt
        asset {
          url
        }
      }
      pageContent {
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`

export default DefaultTemplate
