import React from "react"
import PropTypes from "prop-types"
import RichText from "./rich-text"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { sanityConfig } from "./sanity-config"

const PageContent = ({ content }) => {
  if (!content) {
    return (
      <section>
        <p>Keine Inhalte gefunden</p>
      </section>
    )
  }
  const { _rawContent } = content

  const blocks = _rawContent.map(block => {
    if (block._type === "person") {
      return getPersonDetail(block)
    }
    if (block._type === "team") {
      return getTeamDetail(block)
    }

    return block
  })
  return (
    <section className="page-content">
      <RichText blocks={blocks} />
    </section>
  )
}

PageContent.propTypes = {
  content: PropTypes.object,
}

PageContent.propDefaults = {}

const getPersonDetail = person => {
  return {
    _type: "person",
    name: person.name,
    phone: person.phone,
    email: person.email,
    description: person.description,
    image: {
      asset: getGatsbyImageData(
        person.image,
        {
          width: 125,
          height: 150,
          placeholder: "blurred",
          fit: "contain",
        },
        sanityConfig
      ),
    },
  }
}

const getTeamDetail = team => {
  return {
    _type: "team",
    team: {
      name: team.name,
      league: team.league,
      url: team.url,
      captain: team.captain,
      contactInfoGroup: team.contactInfoGroup,
      contactInfo: team.contactInfo,
      image: {
        asset: getGatsbyImageData(
          team.image,
          {
            width: 180,
            placeholder: "blurred",
            fit: "contain",
          },
          sanityConfig
        ),
      },
    },
  }
}
export default PageContent
